.stay-booker__input--secondary {
    border: 0;
}

.stay-booker__input--dark {
    border: 0;
}

.stay-booker__input {
    color: #CBC7C7;
    border: 0;
    padding: 20px;
    margin-bottom: 15px;
    border-radius: 4px;
}

.sb__date-range-picker {
    position: absolute;
    z-index: 5;
    left: 0;
    top: 100%;
    width: 100%;
}