@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'react-date-range/dist/styles.css'; // main style file
@import '~react-image-gallery/styles/scss/image-gallery.scss';
@import 'react-date-range/dist/theme/default.css'; // theme css file

@import './styles/constants';
@import './styles/utility';
@import './components/global-navbar/global-navbar.scss';
@import './components/ux/input/input.scss';
@import './components/ux/data-range-picker/date-range-picker.scss';
.cus_banner {
  position: relative;
}
.cus_life {
  position: relative;
}

.cus_banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assests/Ellipse.png');
  background-size: contain;
  background-position: left;
  z-index: 1;
  background-repeat: no-repeat;
}
.cus_banner::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('assests/Pill.png');
  background-position: 130px -8px;
  z-index: 1;
  background-repeat: no-repeat;
}

.cus_life::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 180%;
  height: 160%;
  background-image: url('assests/Vector.png');
  background-position: 1px 40px;
  z-index: 9999;
  background-repeat: no-repeat;
}
.cus_mobile_bg {
  position: relative;
}

.cus_mobile_bg::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background-image: url('assests/shape1.png');
  background-position: right;
  z-index: -1;
  background-repeat: no-repeat;
}
.react-responsive-modal-modal {
  max-width: 100% !important;
  width: 55%;
  padding: 0 !important;
}

:focus-visible {
  outline: 0;
}
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.text-red-500 {
  margin-top: 0.5rem;
}
select{
  color: #a7a3af;
}
.dropdown-content {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 150px;
}
.dropdown-item {
  width: 100%;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #e0e0e0; 
  text-align: center;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.dropdown-item:hover {
  color: #2eb1ae;
  background-color: #f0f0f0;
}

.slider {
  margin:0 20px;
  overflow:"hidden";
  padding:2rem 0;
}
/* Custom Scrollbar Styles */
.scrollbar-custom::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

.scrollbar-custom::-webkit-scrollbar-thumb {
  background-color: #fff; /* Color of the scroll thumb */
  border-radius: 9999px; /* Round edges */
}

.scrollbar-custom::-webkit-scrollbar-thumb:hover {
  background-color: #fff; /* Color on hover */
}

.scrollbar-custom::-webkit-scrollbar-track {
  background: #fff; /* Scrollbar track color */
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #0499A0;
  padding: 20px;
  border-radius: 50%;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  color: white; /* Change to your desired color */
  font-size: 16px !important;
}
.swiper-slide {
  margin: 0 !important;
  margin-right: 10px  !important;
}
.container{
  padding:0  15px;
}

html{
  word-break: break-word;
}

.global-navbar__container li.p-4:last-child{
  padding-right: 0 !important;
}
.site-logo__img {
  width: 180px;
  height: 100%;
}
.feed_img {
  border: 2px solid #0499a0;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}
.feedback:hover .feed_img {
  transform: rotate(-10deg);
  transition: 0.3s ease-in-out;
}
div#specialty-single-select, div#gender-single-select, div#language-single-select, div#demo-simple-select {
  padding: 7px;
  padding-right: 25px;
}
#country_cus{
  padding: 7px;
  padding-right: 25px;
}
.grid.grid-cols-2.gap-4.mt-5 input{
padding: 10px;
}
.grid.grid-cols-2.gap-4.mt-5 select{
  padding: 10px;
  }
  .grid.md\:grid-cols-2.grid-cols-1.gap-4 input{
    padding: 10px;
    }
    .grid.md\:grid-cols-2.grid-cols-1.gap-4 select{
      padding: 10px;
      }
      .cus_pad{
        padding: 10px;
      }
      .cus_shadow{
        box-shadow: 10px 11px 82px 0px rgb(160 219 225 / 75%);
        border-radius: 20px;
      }
      .cus_shadow.red{
        box-shadow: 10px 11px 82px 0px rgb(243 164 164 / 75%);
      }
      .react-responsive-modal-modal.small-modal-open {
        max-width: 40% !important;
    }
    .swiper-button-prev{
      opacity:  0 !important;
    }
    .swiper-button-next{
      opacity:  0 !important;
          }
    .mySwiper:hover .swiper-button-prev{
      opacity:  1 !important;
    }
    
    .mySwiper:hover .swiper-button-next{
      opacity:  1 !important;
          }
@media(max-width: 1366px) {

h1{
    font-size: 50px !important;
}

}
@media(max-width: 1300px) {
 .global-navbar__container li.p-4{
  padding:0;
  padding-right: 15px;
  }

.global-navbar__container button{
  font-size: 12px ;
}
.global-navbar__container a{
  font-size: 12px;
}
.global-navbar__container svg {
  color: black;
}
h1{
  font-size: 40px !important;
}
.site-logo__img {
  width: 110px;
}
}

@media(max-width: 1200px) {
  .flex.gap-2.items-center.cus_flex {
    display: block;
}
.flex.gap-2.items-center.cus_flex  p{
  margin-bottom: 0.5rem;
}
.flex.gap-2.items-center.cus_flex  .min-w-max{
  margin-right: 10px;
}
}

@media(max-width: 1024px) {
  .container {
    max-width: 100%;
 
}

}



@media(max-width:1000px){
  html{
    font-size: 65%;
  }
  h1{
    font-size: 34px !important;
  }
  .bg-brand.shadow-2xl.z-20.fixed.right-0.w-1\/2.top-0.h-screen {
    background: #fff;
    padding: 20px;
}
.bg-brand.shadow-2xl.z-20.fixed.right-0.w-1\/2.top-0.h-screen li.p-4 {
  margin-bottom: 15px;
}
}

@media(max-width:1000px){

.react-responsive-modal-modal.small-modal-open {
  max-width: 98% !important;
}
.react-responsive-modal-modal {
  max-width: 94% !important;
  min-width: 94%;
}
}
.message {
  padding: 10px;
  border-radius: 8px;
  margin: 5px 0;
  max-width: 80%; /* Limit message width */
}

.user-message {
  background-color: #e0e0e0; /* Grey background for user messages */
  align-self: flex-end; /* Align user messages to the right */
  margin-left: auto; /* Push user messages to the right */
}

.system-message {
  background-color: #a5d6a7; /* Green background for system messages */
  align-self: flex-start; /* Align system messages to the left */
  margin-right: auto; /* Push system messages to the left */
}

/* Optional: Add display flex to the container to manage alignment */
.messages-container {
  display: flex;
  flex-direction: column;
  padding: 10px;
}